// メニュー
$(function () {
    var activeClass = 'js-active';
    
    var $moreWrap = '.js-more',
        $moreBtn = '.js-more-title',
        $moreBack = '.js-more-back',
        $moreBody = '.js-more-body'; 
    
    function menuReset() {
        $($moreBtn).removeClass(activeClass);
        $($moreBack).removeClass(activeClass);
        $($moreBody).removeClass(activeClass);
    }

    // ハンバーガーメニュー
    var $navBtn = $('.trigger-menu'),
        $navBody = $('.global-menu');
    
    $navBody.fadeIn();

    $navBtn.click(function() {
        $navBtn.toggleClass(activeClass);
        $navBody.toggleClass(activeClass);

        menuReset();
        $navBody.find('.global-list__item').addClass(activeClass);
    });

    // 検索
    var $searchBtn = $('.js-search'),
        $searchBody = $('.js-search-body');
    
    $searchBody.fadeIn();
    
    $searchBtn.click(function() {
        $searchBtn.toggleClass(activeClass);
        $searchBody.toggleClass(activeClass);
    });
    
    // カテゴリ・イベント
    $($moreWrap).each(function () {
        
        $(this).find($moreBtn).click(function () {
            $navBody.find('.global-list__item').removeClass(activeClass);

            $(this).parent($moreWrap).addClass(activeClass);
            $(this).addClass(activeClass);
            $(this).next($moreBody).addClass(activeClass);
        });

        $(this).find($moreBack).click(function () {
            $navBody.find('.global-list__item').removeClass(activeClass);
            menuReset();

            setTimeout(function () {
                $navBody.find('.global-list__item').addClass(activeClass);
            }, 100);
        });
    });
});


// スライダー
$(function () {
    var $container = $('.swiper-custom-parent');

    var $slider = '.js-slider',
        $lSlider = '.js-l-slider',
        $thumbSlider = '.js-thumb-slider',
        $multiplePc = '.js-multiple-pc';
        $multipleSp = '.js-multiple-sp';

    $container.each(function () {
        // デフォルト
        var defaultSlider = new Swiper($(this).find($slider), {
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: $(this).find('.swiper-button-next'),
                prevEl: $(this).find('.swiper-button-prev'),
            },
        });

        // TOP大バナー
        var lSwiper = new Swiper($(this).find($lSlider), {
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: $(this).find('.swiper-button-next'),
                prevEl: $(this).find('.swiper-button-prev'),
            }
        });


        if ($('.js-multiple-pc--item').length > 2) {
            var multiplePcSlider = new Swiper($(this).find($multiplePc), {
                loop: true,
                centeredSlides: true,
                slidesPerView: 3,
                spaceBetween: 30,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: $(this).find('.swiper-button-next'),
                    prevEl: $(this).find('.swiper-button-prev'),
                }
            });
        }


        if ($('.js-multiple-sp--item').length > 1) {
            var multipleSpSlider = new Swiper($(this).find($multipleSp), {
                loop: true,
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 30,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: $(this).find('.swiper-button-next'),
                    prevEl: $(this).find('.swiper-button-prev'),
                }
            });
        }

        var thumbSwiper = new Swiper($(this).find($thumbSlider), {
            navigation: {
                nextEl: $(this).find('.swiper-button-next'),
                prevEl: $(this).find('.swiper-button-prev'),
            },
            pagination: {
                bulletClass:'swiper-pagination-thumb',
                bulletActiveClass:'swiper-pagination-thumb-active',
                el: $(this).next('.swiper-pagination-thumbs'),
                clickable: true,
                renderBullet: function (index, activeClass) {
                    var thumb = $(this).find($thumbSlider).prevObject[0].slides[index].href;
                    return '<span class="' + activeClass + '" style="background-image:url('+ thumb +');"></span>';
                },
            },
        });
    });
});


// 画像プロテクト
$(function() {
    $(".aem-post").find("img").wrap('<span class="js-protect">');
    $('.js-protect').aemProtectImage({
        blankImg: '/images/blank.gif'
    });
});


// スムーズスクロール
$(function() {
    $.fn.aemSmoothScroll();
});


// アコーディオン
$(function() {
    $('.js-accordion').aemAccordion();
});


// タブ、セレクト切り替え
$(function() {
    var $tabWrap = $('.tab-wrap');

    $tabWrap.each(function () {
        var $tabTitle = $(this).find('.tab-category li'),
            $tabTitlesp = $(this).find('.tab-select'),
            $tabBody = $(this).find('.tab-list__area'),
            activeClass = 'active';
        
        
        $tabTitle.click(function () {
            var index = $tabTitle.index(this);
            $tabTitle.removeClass(activeClass);
            $(this).addClass(activeClass);
            $tabBody.removeClass(activeClass).eq(index).addClass(activeClass);
        });

        $tabTitlesp.change(function () {
            var indexsp = $tabTitlesp.prop("selectedIndex");
            $tabBody.hide();
            $tabBody.removeClass(activeClass).eq(indexsp).addClass(activeClass).fadeIn();
        });
    });
});


// ギャラリー
$('[data-fancybox').fancybox({
    protect: true,
    loop: true,
    toolbar: true,
    smallBtn: false,
    buttons: [
        "zoom",
        "thumbs",
        "close"
    ],
});